import { some, every, get } from 'lodash';
import pascalCase from 'lib-frontend-shared/src/helpers/pascalCase';
import config from './config';

// permissions
const READ = 'read';
const CREATE = 'create';
const CLONE = 'clone';
const EDIT = 'edit';
const DELETE = 'delete';
const REPROCESS = 'reprocess';
const REASSIGN = 'reassign';
const RETRY = 'retry';
const UPDATE_STATUS = 'update status';
const CONFIRM = 'confirm';
const CANCEL = 'cancel';
const CHANGE_SEQUENCE = 'change sequence';
const RESET_PASSWORD = 'reset password';
const RETRIGGER = 'RETRIGGER';

// permission groups
// -------------------

// anyone
const NONE = 'NONE';

// data entities
const ALERT = 'ALERT';
const SHIPMENT = 'SHIPMENT';
const ORDER = 'ORDER';
const MANIFEST = 'MANIFEST';
const RETURN_REQUEST = 'RETURN_REQUEST';
const REPORT = 'REPORT';
const FEEDBACK_REPORT = 'FEEDBACK_REPORT';
const NOTIFICATION_REPORT = 'NOTIFICATION_REPORT';
const REGIONAL_ANALYSIS_REPORT = 'REGIONAL_ANALYSIS_REPORT';
const SETTLEMENT_REPORT = 'SETTLEMENT_REPORT';

// settings
const CATALOG = 'CATALOG';
const CLICK_N_SHIP = 'CLICK_N_SHIP';
const CARRIER_ACCOUNT = 'CARRIER_ACCOUNT';
const CARRIER_CAPACITY = 'CARRIER_CAPACITY';
const SHIPMENT_COSTING = 'SHIPMENT_COSTING';
const CARRIER_NETWORK = 'CARRIER_NETWORK';
const LOCATION = 'LOCATION';
const CUSTOM_ATTRIBUTE = 'CUSTOM_ATTRIBUTE';
const ROUTING_RULE = 'ROUTING_RULE';
const ROUTING_RULE_SET = 'ROUTING_RULE_SET';
const SERVICE_LEVEL = 'SERVICE_LEVEL';
const SERVICE_LEVEL_SET = 'SERVICE_LEVEL_SET';
const NOTIFICATION_SETTING = 'NOTIFICATION_SETTING';
const NOTIFICATION_CONFIG_SETTING = 'NOTIFICATION_CONFIG_SETTING';
const API_CLIENT = 'API_CLIENT';
const CONNECTOR = 'CONNECTOR';
const PACKING_TEMPLATE = 'PACKING_TEMPLATE';
const WEBHOOK = 'WEBHOOK';
const SYSTEM_LOGS = 'SYSTEM_LOGS';
const ACCOUNT_SETTING = 'ACCOUNT_SETTING';
const USER = 'User';
const RETURN_REQUEST_SETTING = 'RETURN_REQUEST_SETTING';
const REASON_CODE = 'REASON_CODE';
const CX_TEMPLATES = 'CX_TEMPLATES';
const INTEGRATION_MONITOR = 'INTEGRATION_MONITOR';
const MONITORING_DASHBOARD = 'MONITORING_DASHBOARD';
const SETTINGS = 'SETTINGS';

const accountOnlyEntities = {
  CARRIER_ACCOUNT,
  CLICK_N_SHIP,
  CARRIER_CAPACITY,
  SHIPMENT_COSTING,
  CARRIER_NETWORK,
  LOCATION,
  CUSTOM_ATTRIBUTE,
  REASON_CODE,
  ACCOUNT_SETTING,
  USER,
};

const toggleDependantLevelEntities = {
  NOTIFICATION_SETTING,
  NOTIFICATION_CONFIG_SETTING,
};

export const permissionGroups = {
  // exists at merchant level only
  ALERT,
  CATALOG,
  SHIPMENT,
  ORDER,
  MANIFEST,
  NONE,
  RETURN_REQUEST,
  REPORT,
  FEEDBACK_REPORT,
  NOTIFICATION_REPORT,
  REGIONAL_ANALYSIS_REPORT,
  SETTLEMENT_REPORT,
  API_CLIENT,
  CONNECTOR,
  INTEGRATION_MONITOR,
  MONITORING_DASHBOARD,
  WEBHOOK,
  RETURN_REQUEST_SETTING,
  CX_TEMPLATES,
  PACKING_TEMPLATE,
  ROUTING_RULE,
  ROUTING_RULE_SET,
  SERVICE_LEVEL,
  SERVICE_LEVEL_SET,
  SETTINGS,
  // exists at account level + it could also exist at merchant level based on toggle
  ...toggleDependantLevelEntities,

  // exists only at account level
  ...accountOnlyEntities,
};

const allLocationAccessGroups = {
  NOTIFICATION_SETTING,
  NOTIFICATION_CONFIG_SETTING,
  ROUTING_RULE,
  ROUTING_RULE_SET,
  FEEDBACK_REPORT,
  NOTIFICATION_REPORT,
  REGIONAL_ANALYSIS_REPORT,
  SETTLEMENT_REPORT,
  CARRIER_ACCOUNT,
  CLICK_N_SHIP,
  CARRIER_CAPACITY,
  SHIPMENT_COSTING,
  CARRIER_NETWORK,
};

export const canRead = (
  permissions = [],
  allLocationAccess,
) => permissions.includes(READ) && (allLocationAccess === undefined || allLocationAccess);
export const canCreate = (permissions = []) => permissions.includes(CREATE);
export const canClone = (permissions = []) => permissions.includes(CLONE);
export const canEdit = (permissions = []) => permissions.includes(EDIT);
export const canUpdateStatus = (permissions = []) => permissions.includes(UPDATE_STATUS);
export const canChangeSequence = (permissions = []) => permissions.includes(CHANGE_SEQUENCE);
export const canReprocess = (permissions = []) => permissions.includes(REPROCESS);
export const canReassign = (permissions = []) => permissions.includes(REASSIGN);
export const canRetry = (permissions = []) => permissions.includes(RETRY);
export const canCancel = (permissions = []) => permissions.includes(CANCEL);
export const canConfirm = (permissions = []) => permissions.includes(CONFIRM);
export const canResetPassword = (permissions = []) => permissions.includes(RESET_PASSWORD);
export const canDelete = (permissions = []) => permissions.includes(DELETE);
export const canRetrigger = (permissions = []) => permissions.includes(RETRIGGER);

const baseAlertPermissions = [READ];
const baseReportsPermissions = [READ];
const baseCarriersPermissions = [READ, CREATE, CLONE, EDIT, DELETE, UPDATE_STATUS];
const baseClicknShipPermissions = [READ, CREATE, CLONE, EDIT, DELETE, UPDATE_STATUS];
const baseCommsSettingsPermissions = [READ, CREATE, CLONE, EDIT, DELETE];
const baseCustomPropsPermissions = [READ, CREATE, EDIT];
const baseReasonCodePermissions = [READ, CREATE, DELETE, EDIT];
const baseLocationsPermissions = [READ, CREATE, CLONE, EDIT, DELETE, UPDATE_STATUS];
const baseReturnRequestPermissions = [READ, CREATE, CLONE, EDIT, CANCEL];
const baseReturnRequestSettingsPermissions = [READ, CREATE, EDIT, DELETE];
const baseManifestPermissions = [READ, CREATE, CLONE, EDIT, CANCEL];
const baseCXTemplatesPermissions = [READ, EDIT];
const baseRulesPermissions = [
  READ,
  CREATE,
  CLONE,
  DELETE,
  EDIT,
  UPDATE_STATUS,
  CHANGE_SEQUENCE,
];
const baseRuleSetsPermissions = [
  READ,
  CREATE,
  CLONE,
  DELETE,
  EDIT,
  UPDATE_STATUS,
  CHANGE_SEQUENCE,
];
const basePackageTemplatePermissions = [
  READ,
  CREATE,
  CLONE,
  DELETE,
  EDIT,
  UPDATE_STATUS,
  CHANGE_SEQUENCE,
];
const baseServiceLevelsPermissions = [READ, CREATE, CLONE, DELETE, EDIT, UPDATE_STATUS];
const baseServiceLevelSetsPermissions = [READ, CREATE, CLONE, DELETE, EDIT, UPDATE_STATUS];
const baseShipmentPermissions = [
  READ,
  CREATE,
  CLONE,
  EDIT,
  REPROCESS,
  RETRY,
  REASSIGN,
  UPDATE_STATUS,
  CONFIRM,
  CANCEL,
];
const baseOrderPermissions = [
  READ,
  CREATE,
  CLONE,
  EDIT,
  REPROCESS,
  RETRY,
  REASSIGN,
  UPDATE_STATUS,
  CONFIRM,
  CANCEL,
];
const baseAccountSettingsPermissions = [READ, CREATE, EDIT, RESET_PASSWORD, DELETE, UPDATE_STATUS];
const baseApiClientPermissions = [READ, CREATE, CLONE, DELETE, EDIT, UPDATE_STATUS];
const baseCatalogPermissions = [READ, CREATE, DELETE, EDIT];
const baseIntegrationMonitorPermissions = [READ];
const baseMonitoringDashboardPermissions = [READ];
const baseUsersPermissions = [READ, CREATE, EDIT, RESET_PASSWORD, DELETE];
const baseConnectorSettingsPermissions = [READ, CREATE, CLONE, DELETE, EDIT, UPDATE_STATUS];
const baseWebhookTriggerSettingsPermissions = [READ, RETRIGGER];
const baseWebhookSettingsPermissions = [...baseWebhookTriggerSettingsPermissions, CREATE, CLONE, DELETE, EDIT, UPDATE_STATUS];
const baseSystemLogsPermissions = [READ];
const baseCarrierNetworkPermissions = [READ, CREATE, CLONE, DELETE, EDIT, UPDATE_STATUS];
const baseCarrierCapacityPermissions = [READ, CREATE, CLONE, DELETE, EDIT, UPDATE_STATUS];
const baseCarrierCostingPermissions = [READ, CREATE, CLONE, DELETE, EDIT, UPDATE_STATUS];

const restrictAccessToAccountEntities = (permissions) => (showingAccountLevel) => (
  showingAccountLevel ? [] : permissions
);

// Custom User Role

const alshayaCSManagerPermissions = {
  [CATALOG]: baseCatalogPermissions,
  [REASON_CODE]: [READ],
  [RETURN_REQUEST_SETTING]: baseReturnRequestSettingsPermissions,
  [RETURN_REQUEST]: baseReturnRequestPermissions,
  [CUSTOM_ATTRIBUTE]: [READ],
  [ALERT]: baseAlertPermissions,
  [REPORT]: baseReportsPermissions,
  [SHIPMENT]: baseShipmentPermissions,
  [MANIFEST]: baseManifestPermissions,
  [LOCATION]: [READ],
  [SERVICE_LEVEL]: [READ],
  [SERVICE_LEVEL_SET]: [READ],
  [ROUTING_RULE]: [READ],
  [ROUTING_RULE_SET]: [READ],
  [NOTIFICATION_SETTING]: restrictAccessToAccountEntities(baseCommsSettingsPermissions),
  [CX_TEMPLATES]: baseCXTemplatesPermissions,
  [WEBHOOK]: [RETRIGGER],
  [SETTINGS]: [READ],
};

const alshayaLogisticsManagerPermissions = {
  ...alshayaCSManagerPermissions,
  [LOCATION]: baseLocationsPermissions,
  [SERVICE_LEVEL]: baseServiceLevelsPermissions,
  [SERVICE_LEVEL_SET]: baseServiceLevelSetsPermissions,
  [CARRIER_CAPACITY]: baseCarrierCapacityPermissions,
  [SHIPMENT_COSTING]: baseCarrierCostingPermissions,
  [CARRIER_NETWORK]: baseCarrierNetworkPermissions,
  [CARRIER_ACCOUNT]: baseCarriersPermissions,
  [ROUTING_RULE]: baseRulesPermissions,
  [ROUTING_RULE_SET]: baseRuleSetsPermissions,
  [WEBHOOK]: baseWebhookTriggerSettingsPermissions,

  [CX_TEMPLATES]: [],
  [NOTIFICATION_SETTING]: [],
};

// role permissions
// route id (as defined in routes.jsx) -> permission
const viewerPermissions = {
  [CATALOG]: [READ],
  [ROUTING_RULE]: [READ],
  [ROUTING_RULE_SET]: [READ],
  [SERVICE_LEVEL]: [READ],
  [SERVICE_LEVEL_SET]: [READ],
  [SETTINGS]: [READ],
  [SHIPMENT]: [READ],
  [ORDER]: [READ],
  [REPORT]: baseReportsPermissions,
  [ALERT]: baseAlertPermissions,
  [FEEDBACK_REPORT]: baseReportsPermissions,
  [NOTIFICATION_REPORT]: baseReportsPermissions,
  [REGIONAL_ANALYSIS_REPORT]: baseReportsPermissions,
  [SETTLEMENT_REPORT]: baseReportsPermissions,
  [MANIFEST]: [READ],
  [RETURN_REQUEST]: [READ],
  [LOCATION]: [READ],
  [LOCATION]: [READ],
  [CUSTOM_ATTRIBUTE]: [READ],
  [REASON_CODE]: [READ],
};

const ITManagerPermissions = {
  ...viewerPermissions,
  [API_CLIENT]: baseApiClientPermissions,
  [INTEGRATION_MONITOR]: baseIntegrationMonitorPermissions,
  [CONNECTOR]: baseConnectorSettingsPermissions,
  [WEBHOOK]: baseWebhookSettingsPermissions,
  [SYSTEM_LOGS]: baseSystemLogsPermissions,
};

const operatorPermissions = {
  [ROUTING_RULE]: [READ],
  [ROUTING_RULE_SET]: [READ],
  [SERVICE_LEVEL]: [READ],
  [SERVICE_LEVEL_SET]: [READ],
  [SETTINGS]: [READ],
  [ALERT]: baseAlertPermissions,
  [SHIPMENT]: baseShipmentPermissions,
  [MANIFEST]: baseManifestPermissions,
  [RETURN_REQUEST]: baseReturnRequestPermissions,
  [REPORT]: baseReportsPermissions,
  [FEEDBACK_REPORT]: baseReportsPermissions,
  [NOTIFICATION_REPORT]: baseReportsPermissions,
  [REGIONAL_ANALYSIS_REPORT]: baseReportsPermissions,
  [SETTLEMENT_REPORT]: baseReportsPermissions,
  [SYSTEM_LOGS]: baseSystemLogsPermissions,
  [CUSTOM_ATTRIBUTE]: [READ],
  [REASON_CODE]: [READ],
  [LOCATION]: [READ],
};

const managerPermissions = {
  ...operatorPermissions,
  [CATALOG]: baseCatalogPermissions,
  [ROUTING_RULE]: baseRulesPermissions,
  [ROUTING_RULE_SET]: baseRuleSetsPermissions,
  [SERVICE_LEVEL]: baseServiceLevelsPermissions,
  [SERVICE_LEVEL_SET]: baseServiceLevelSetsPermissions,
  [NOTIFICATION_SETTING]: restrictAccessToAccountEntities(baseCommsSettingsPermissions),
  [NOTIFICATION_CONFIG_SETTING]: restrictAccessToAccountEntities(baseCommsSettingsPermissions),
  [RETURN_REQUEST_SETTING]: baseReturnRequestSettingsPermissions,
  [WEBHOOK]: baseWebhookTriggerSettingsPermissions,
  [CX_TEMPLATES]: baseCXTemplatesPermissions,
};

const accountManagerPermissions = {
  ...managerPermissions,
  [NOTIFICATION_SETTING]: baseCommsSettingsPermissions,
  [NOTIFICATION_CONFIG_SETTING]: baseCommsSettingsPermissions,
  [CARRIER_CAPACITY]: baseCarrierCapacityPermissions,
  [SHIPMENT_COSTING]: baseCarrierCostingPermissions,
  [CARRIER_NETWORK]: baseCarrierNetworkPermissions,
  [CARRIER_ACCOUNT]: baseCarriersPermissions,
  [LOCATION]: baseLocationsPermissions,
};

const adminPermissions = {
  ...accountManagerPermissions,
  [CLICK_N_SHIP]: baseClicknShipPermissions,
  [CUSTOM_ATTRIBUTE]: baseCustomPropsPermissions,
  [REASON_CODE]: baseReasonCodePermissions,
  [ACCOUNT_SETTING]: baseAccountSettingsPermissions,
  [API_CLIENT]: baseApiClientPermissions,
  [INTEGRATION_MONITOR]: baseIntegrationMonitorPermissions,
  [CONNECTOR]: baseConnectorSettingsPermissions,
  [PACKING_TEMPLATE]: basePackageTemplatePermissions,
  [WEBHOOK]: baseWebhookSettingsPermissions,
  [USER]: baseUsersPermissions,
};

const superAdminPermissions = {
  ...adminPermissions,
  [ORDER]: baseOrderPermissions,
  [MONITORING_DASHBOARD]: baseMonitoringDashboardPermissions,
};

const customRoleMappings = {
  'alshaya-cs-manager': alshayaCSManagerPermissions,
  'alshaya-logistics-manager': alshayaLogisticsManagerPermissions,
};

const roleBasedPermissions = (tenantId) => {
  const userRolesPermissions = {
    viewer: viewerPermissions,
    'it-manager': ITManagerPermissions,
    operator: operatorPermissions,
    manager: managerPermissions,
    'account-manager': accountManagerPermissions,
    admin: adminPermissions,
    owner: adminPermissions,
    'super-admin': superAdminPermissions,
  };
  const customRolePermissions = (
    get(config, `customUserRoles.${(tenantId || '').toUpperCase()}`)
    || get(config, 'customUserRoles._ANY')
    || []
  ).reduce((acc, role) => ({
    ...acc,
    [role]: customRoleMappings[role],
  }), {});

  return {
    ...userRolesPermissions,
    ...customRolePermissions,
  };
};

export function getPermissionsForRoute({ permissionGroup }, auth, globals = {}) {
  const { role = '', locations = [], tenantId } = auth;
  const allLocationAccess = allLocationAccessGroups[permissionGroup]
    ? !locations.length
    : undefined;
  let permissions = roleBasedPermissions(tenantId)[role]?.[permissionGroup] || [];
  let showingAccountLevel = permissionGroup in accountOnlyEntities;
  if (permissionGroup in toggleDependantLevelEntities) {
    showingAccountLevel = globals[`canShowAccount${pascalCase(permissionGroup)}s`];
  }

  if (typeof permissions === 'function') {
    permissions = permissions(showingAccountLevel);
  }

  return {
    canRead: canRead(permissions, allLocationAccess),
    canCreate: canCreate(permissions),
    canClone: canClone(permissions),
    canEdit: canEdit(permissions),
    canUpdateStatus: canUpdateStatus(permissions),
    canChangeSequence: canChangeSequence(permissions),
    canReprocess: canReprocess(permissions),
    canReassign: canReassign(permissions),
    canRetry: canRetry(permissions),
    canCancel: canCancel(permissions),
    canConfirm: canConfirm(permissions),
    canResetPassword: canResetPassword(permissions),
    canDelete: canDelete(permissions),
    canRetrigger: canRetrigger(permissions),
  };
}

export const getPermissionsForGroup = (
  permissionGroup,
  auth,
  globals,
) => getPermissionsForRoute({ permissionGroup }, auth, globals);

export const isAnyPermitted = some;

export const isAllPermitted = every;
