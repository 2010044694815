import { push, replace } from '../actions/route';
import { history } from '../store';

const routingType = {
  push,
  replace,
};
export default (path = '', savePath = true, explicitRoute, type = 'push') => {
  history.block(() => true);
  routingType[type](path, savePath, explicitRoute);
};
